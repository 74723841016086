@tailwind base;
@tailwind components;
@tailwind utilities;

.logo-container {
  position: relative;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s;
}

.default-logo {
  opacity: 1;
}

.orange-logo {
  opacity: 0;
}

.show-orange {
  opacity: 1;
}
